import { Controller } from "@hotwired/stimulus"
import { useTargetMutation } from "stimulus-use";
// Connects to data-controller="language-select"
export default class extends Controller {
  static values = {
    activeLocale: String
  }

  static targets = [ "fields", "button", "question" ]

  connect() {
    useTargetMutation(this, { targets: ["fields"]})
    this.activateButton();
    this.hideAll();
    this.element.getElementsByClassName(`fields-${this.activeLocaleValue}`)[0].classList.remove('hidden');
  }

  hideAll() {
    this.fieldsTargets.forEach((element) => {
      element.classList.add('hidden');
    })
  }

  activateButton() {
    this.buttonTargets.forEach((element) => {
      if(element.dataset.locale == this.activeLocaleValue) {
        element.classList.add('bg-gray-200', 'border', 'border-gray-400');
      } else {
        element.classList.remove('bg-gray-200', 'border', 'border-gray-400');

      }
    })
  }

  setLocale(event) {
    const locale = event.currentTarget.dataset.locale;
    this.activeLocaleValue = locale;
    this.activateButton();
    this.hideAll();
    this.element.getElementsByClassName(`fields-${locale}`)[0].classList.remove('hidden');
  }

  // Target mutation

  fieldsTargetAdded(element) {
    // triggered when a fieldsTarget is added
    console.log("Element added", element)
  }

  fieldsTargetRemoved(element) {
    // triggered when a fieldsTarget is removed
  }

  fieldsTargetChanged(element) {
    // triggered when a fieldsTarget is changed
  }
}
